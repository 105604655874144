import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from '../connect';
import { Breakpoint } from '@avensia/scope';
import * as style from '../Style';

export const SPACING_DEFAULT = style.pixelsToUnit(25);
export const SPACING_MOBILE = style.pixelsToUnit(15);
export const BOX_CLASSNAME = 'purefun-box';
export const BOX_ITEM_CLASSNAME = 'purefun-box-item';

const fractions = {
  '1:2': 1 / 2,
  '1:3': 1 / 3,
  '1:4': 1 / 4,
  '2:3': 2 / 3,
  '3:4': 3 / 4,
};

export type LayoutAliasType = keyof typeof fractions;

type LayoutColumnType = LayoutAliasType[] & {
  0: LayoutAliasType;
  1: LayoutAliasType[] & {
    0: LayoutAliasType;
    1: LayoutAliasType;
  };
};

export type LayoutType = LayoutAliasType | LayoutColumnType;

function spacingDefaulter(value: boolean | string, currentBreakpoint: number) {
  return value === true ? (currentBreakpoint > Breakpoint.Tiny ? SPACING_DEFAULT : SPACING_MOBILE) : value;
}

function isThin(fraction: number) {
  return fraction <= 1 / 3;
}

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minWidth: 0,
});

const Block = styled.div({
  flexGrow: 1,
  minWidth: 0,
});

type BlockEntityType = {
  fraction: number;
  element: React.ReactNode;
};

type ColumnEntityType = {
  fraction: number;
  columns: BlockEntityType[];
};

function sort(entities: BlockEntityType[]) {
  return entities.sort((a, b) => b.fraction - a.fraction);
}

function convert(
  row: LayoutType[],
  children: React.ReactNode[],
  currentBreakpoint: number,
  allowSorting?: boolean,
  ): (BlockEntityType | ColumnEntityType)[] {
  if (currentBreakpoint > Breakpoint.Small) {
    return row.map(entry => {
      if (typeof entry === 'string') {
        return {
          fraction: fractions[entry],
          element: children.shift(),
        };
      }

      return {
        fraction: fractions[entry[0]],
        columns: entry[1].map(entry => ({
          fraction: fractions[entry],
          element: children.shift(),
        })),
      };
    });
  }

  // Flatten all blocks to full or half width
  if (currentBreakpoint > Breakpoint.Micro) {
    const entities = row.reduce<BlockEntityType[]>((current, item) => {
      if (typeof item === 'string') {
        return [
          ...current,
          {
            fraction: isThin(fractions[item]) ? 0.5 : 1,
            element: children.shift(),
          },
        ];
      }

      const value = isThin(fractions[item[0]]) ? 0.5 : 1;
      return [
        ...current,
        ...item[1].map(() => ({
          fraction: value,
          element: children.shift(),
        })),
      ];
    }, []);

    return allowSorting ? sort(entities) : entities;
  }

  // Flatten all blocks to full width
  const entities = row.reduce<BlockEntityType[]>(
    (current, entry) =>
      typeof entry === 'string'
        ? [
          ...current,
          {
            fraction: 1,
            element: children.shift(),
          },
        ]
        : [
          ...current,
          ...entry[1].map(() => ({
            fraction: 1,
            element: children.shift(),
          })),
        ],
    [],
  );

  return allowSorting ? sort(entities) : entities;
}

function isColumn(entity: BlockEntityType | ColumnEntityType): entity is ColumnEntityType {
  return 'columns' in entity;
}

function wrap(spacingBetweenBlocks: boolean | string, currentBreakpoint: number) {
  const spacing = spacingDefaulter(spacingBetweenBlocks, currentBreakpoint);
  return (entity: BlockEntityType | ColumnEntityType, index: number): React.ReactNode => {
    return isColumn(entity) ? (
      <Column
        key={index}
        css={{
          flexBasis: `${entity.fraction * 100}%`,
        }}
      >
        {entity.columns.map(wrap(spacingBetweenBlocks, currentBreakpoint))}
      </Column>
    ) : (
        <Block
          key={index}
          css={{
            ...(spacing && {
              padding: {
                xy: `calc(${spacing} / 2)`,
              },
            }),
            flexBasis: spacing ? `calc(${entity.fraction * 100}% - ${spacing})` : `${entity.fraction * 100}%`,
          }}
        >
          {entity.element}
        </Block>
      );
  };
}

type RequiredPropType = {
  layout: LayoutType[];
  spacingBetweenBlocks?: boolean | string;
  allowSorting?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = StyledProps & RequiredPropType & ConnectStateType;

class BoxLayout extends React.Component<PropType> {
  render() {
    const children = React.Children.toArray(this.props.children);
    const entities = convert(this.props.layout, children, this.props.currentBreakpoint, this.props.allowSorting);
    const spacing = spacingDefaulter(this.props.spacingBetweenBlocks, this.props.currentBreakpoint);
    return (
      <styled.Div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          ...(spacing && {
            margin: {
              xy: `calc(-${spacing} / 2)`,
            },
          }),
          ...(this.props.currentBreakpoint > Breakpoint.Small && {
            flexWrap: 'nowrap',
          }),
        }}
        className={BOX_CLASSNAME}
      >
        {entities.map(wrap(this.props.spacingBetweenBlocks, this.props.currentBreakpoint))}
      </styled.Div>
    );
  }
}



export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
  )(BoxLayout),
);
