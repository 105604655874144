import { styled } from '@glitz/react';
import { minTinyMediaQuery, minLargeMediaQuery, pixelsToUnit } from 'Shared/Style';
import { SPACING_DEFAULT, SPACING_MOBILE } from 'Shared/BoxLayout';

export default styled({
  marginTop: SPACING_MOBILE,
  [minLargeMediaQuery]: {
    marginTop: pixelsToUnit(40),
  },
  [minTinyMediaQuery]: {
    marginTop: SPACING_DEFAULT,
  },
});
