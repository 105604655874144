/**
 * @ComponentFor HtmlBlock
 */
import React, { useEffect } from 'react';
import { LinksContainer } from '@avensia/scope';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import HtmlBlockType from './HtmlBlock.type';
import padStyled from 'Shared/Blocks/PaddedBlock';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { pixelsToUnit } from 'Shared/Style';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import { trackPromotionImpression } from 'TrackingInformation';

const HtmlBlock = padStyled((props: HtmlBlockType & StyledProps) => {
  useEffect(() => {
    trackPromotionImpression(props.promotionImpression);
  }, []);

  const backgroundColor = epiPropertyValue(props.backgroundColor);
  const css: Style = {
    backgroundColor: !!backgroundColor ? backgroundColor : 'transparent',
    ...(epiPropertyValue(props.layout) === 'wide'
      ? {
          maxWidth: '100%',
        }
      : {
          margin: {
            y: 0,
            x: 'auto',
          },
          maxWidth: pixelsToUnit(600),
        }),
  };
  return (
    <styled.Div css={props.compose()} className={BOX_ITEM_CLASSNAME}>
      <EpiProperty
        propertyComponentProps={{ keepImageSizes: epiPropertyValue(props.displayImage) }}
        for={props.html}
        // tslint:disable-next-line:jsx-no-lambda
        component={(innerProps) => (
          <LinksContainer>
            <styled.Div css={css} {...innerProps} />
          </LinksContainer>
        )}
        className="html-block"
      />
    </styled.Div>
  );
});

export default HtmlBlock;
